import { actionTypes } from "./actions";

export const initialState = {
    listTypeValue: [],
};

const listTypeValueReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LIST_TYPE_VALUE_GET_REQUEST:
      return {
        ...state,
        isListTypeValueLoading: true,
      };
    case actionTypes.LIST_TYPE_VALUE_GET_RECEIVE:
      return {
        ...state,
        listTypeValue: action.payload.map((dt, index) => ({ ...dt, id: index })),
        isListTypeValueLoading: false,
      };
    case actionTypes.LIST_TYPE_VALUE_GET_FAIL:
      return {
        ...state,
        isListTypeValueLoading: false,
       
      };
      case actionTypes.LIST_TYPE_VALUE_OEMMODELSYNC_REQUEST:
        return {
          ...state,
          isListTypeValueLoading: true,
        };
      case actionTypes.LIST_TYPE_VALUE_OEMMODELSYNC_RECEIVE:
        return {
          ...state,
          isListTypeValueLoading: false,
        };
      case actionTypes.LIST_TYPE_VALUE_OEMMODELSYNC_FAIL:
        return {
          ...state,
          isListTypeValueLoading: false,
         
        };
    default:
      return state;
  }
};

export default listTypeValueReducer;
