
import {getAllActiveListTypeValueAPI, updateListTypeValueAPI, createListTypeValueAPI, deleteListTypeValueAPI,syncListOemModelWithSAMDBAPI} from './endpoints';
import { actions } from 'store/Reducer/actions';

export const actionTypes = {
    LIST_TYPE_VALUE_GET_REQUEST: "LIST_TYPE_VALUE_GET_REQUEST",
    LIST_TYPE_VALUE_GET_RECEIVE: "LIST_TYPE_VALUE_GET_RECEIVE",
    LIST_TYPE_VALUE_GET_FAIL: "LIST_TYPE_VALUE_GET_FAIL",
    LIST_TYPE_VALUE_OEMMODELSYNC_REQUEST: "LIST_TYPE_VALUE_OEMMODELSYNC_REQUEST",
    LIST_TYPE_VALUE_OEMMODELSYNC_RECEIVE: "LIST_TYPE_VALUE_OEMMODELSYNC_RECEIVE",
    LIST_TYPE_VALUE_OEMMODELSYNC_FAIL: "LIST_TYPE_VALUE_OEMMODELSYNC_FAIL"
  };

const requestListTypeValue= () => ({
    type: actionTypes.LIST_TYPE_VALUE_GET_REQUEST,
});
const receiveListTypeValue = (payload) => ({
    type: actionTypes.LIST_TYPE_VALUE_GET_RECEIVE,
    payload,
});
const failureListTypeValue = (payload) => ({
    type: actionTypes.LIST_TYPE_VALUE_GET_FAIL,
    payload,
});

const requestListTypeOemModelSync= () => ({
    type: actionTypes.LIST_TYPE_VALUE_OEMMODELSYNC_REQUEST,
});
const receiveListTypeOemModelSync = () => ({
    type: actionTypes.LIST_TYPE_VALUE_OEMMODELSYNC_RECEIVE,
});
const failureListTypeOemModelSync = () => ({
    type: actionTypes.LIST_TYPE_VALUE_OEMMODELSYNC_FAIL,
});

export const getAllActiveListTypeValueAction = ( activeListType) => {
    return async (dispatch) => {
        dispatch(requestListTypeValue());
        try {
            const response = await getAllActiveListTypeValueAPI();
            if (response?.status === 200) {
                let customizeResult = [];
                response?.data?.data.forEach(element => {
                const listType = activeListType.find(({pkListType})=>pkListType===element.fkListType)        
                element.listTypeName = listType?.listTypeName;
                customizeResult.push(element);
             })
                dispatch(receiveListTypeValue(response?.data?.data));
            }
        } catch (error) {
            dispatch(failureListTypeValue(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};
export const updateListTypValueAction = ( payload, activeListType) => {
    return async (dispatch) => {
        dispatch(requestListTypeValue());
        try {
            const response = await updateListTypeValueAPI(payload.fkListType, payload);
            if (response?.status === 200) {
                dispatch(actions.successAlert("List Type Value updated successfully"));
            }
            dispatch(getAllActiveListTypeValueAction(activeListType));
        } catch (error) {
            dispatch(failureListTypeValue(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};
export const createListTypeValueAction = (payload,activeListType) => {
    return async (dispatch) => {
        dispatch(requestListTypeValue());
        try {
            const response = await createListTypeValueAPI(payload);
            if (response?.status === 201) {
                dispatch(actions.successAlert("List Type Value added successfully"));
            }
            dispatch(getAllActiveListTypeValueAction(activeListType));
        } catch (error) {
            dispatch(failureListTypeValue(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}
export const deleteListTypeValueAction = (payload, activeListType) => {
    return async (dispatch) => {
        dispatch(requestListTypeValue());
        try {
            const response = await deleteListTypeValueAPI(payload);
            if (response?.status === 200) {
                dispatch(actions.successAlert("List Type Value deleted successfully"));
            }
            dispatch(getAllActiveListTypeValueAction(activeListType));
        } catch (error) {
            dispatch(failureListTypeValue(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
}

export const updateListTypeOemModelSyncAction = () => {
    return async (dispatch) => {
        dispatch(requestListTypeOemModelSync());
        try {
            const response = await syncListOemModelWithSAMDBAPI();
            if (response?.status === 200) {
                dispatch(actions.successAlert("OEM Model Synced successfully"));
            }
            dispatch(receiveListTypeOemModelSync());
        } catch (error) {
            dispatch(failureListTypeOemModelSync(error?.response?.data?.data));
            if (error?.response) {
                dispatch(actions.errorAlert(error?.response?.data?.errorMessage));
            } else {
                 dispatch(actions.errorAlert( "Something went wrong, please contact administrator"));
            }
        }
    };
};