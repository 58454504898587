import { actionTypes } from "../State/actions";
export const initialState = {
    equipmentType: [],
    equipmentPermTypeData:[],
    equipmentDynForms: [],
    equipmentMainData: [],
    equipmentMainDataCols: [],
    ModelOemList: [],
    machineTypeDictUIColMappingsMS:[],
    equipmentHistTypeData:[],
    equipmentHistDictUIColMappingsMS:[],
    isHistoryRowsDataLoading:false,
    isRowDataTypeLoading:false,
    equipmentRowData:[]
};

const equipmentTypeMainReducer = (state = initialState, action) => {
  console.log("My Action", action.type);
  switch (action.type) {
    case actionTypes.EQUIPMENT_TYPE_GET_REQUEST:
      return {
        ...state,
        isequipmentTypeLoading: true,
      };
    case actionTypes.EQUIPMENT_TYPE_GET_RECEIVE:
      let equipmentData = action.payload.equipmentData
      
      return {
        ...state,
        equipmentType: equipmentData.map((dt, index) => ({ ...dt, id: index })),
        isequipmentTypeLoading: false,
      };

    case actionTypes.EQUIPMENT_TYPE_GET_FAIL:
      return {
        ...state,
        isequipmentTypeLoading: false,
       
      };

      case actionTypes.EQUIPMENT_TYPE_PERM_POST_REQUEST:
        return {
          ...state,
          isequipmentTypeLoading: true,
        };
      case actionTypes.EQUIPMENT_TYPE_PERM_POST_RECEIVE:
        let equipmentPermData = action.payload.equipmentData
        
        return {
          ...state,
          equipmentPermTypeData: equipmentPermData.map((dt, index) => ({ ...dt, id: index })),
          isequipmentTypeLoading: false,
        };
  
      case actionTypes.EQUIPMENT_TYPE_PERM_POST_FAIL:
        return {
          ...state,
          isequipmentTypeLoading: false,
         
        };
  
        case actionTypes.EQUIPMENT_TYPE_HIST_POST_REQUEST:
          return {
            ...state,
            isHistoryRowsDataLoading: true,
          };
        case actionTypes.EQUIPMENT_TYPE_HIST_POST_RECEIVE:
          let equipmentHistData = action.payload.historystgmachineData
          
          return {
            ...state,
            equipmentHistTypeData: equipmentHistData.map((dt, index) => ({ ...dt, id: index })),
            equipmentHistDictUIColMappingsMS:action.payload.stgmachineTypeDictUIColMappings,
            isHistoryRowsDataLoading: false,
          };
    
        case actionTypes.EQUIPMENT_TYPE_HIST_POST_FAIL:
          return {
            ...state,
            isHistoryRowsDataLoading: false,
           
          };
  
      case actionTypes.EQUIPMENT_DYFORM_GET_REQUEST:
        return {
          ...state,
          isdynamicFormTypeLoading: true,
        };
      case actionTypes.EQUIPMENT_DYFORM_GET_RECEIVE:
        console.log("My Action Dynamic", action.payload);
        let DynamicFormData = action.payload.eqmDynamicDisplayResponse
        let DynModelOem=action.payload.tblOemModelResponseDTO
        
        return {
          ...state,
          //equipmentDynForms: DynamicFormData.map((dt, index) => ({ ...dt, id: index })),
          equipmentDynForms: DynamicFormData,
          ModelOemList:DynModelOem,
          isdynamicFormTypeLoading: false,
        };
  
      case actionTypes.EQUIPMENT_DYFORM_GET_FAIL:
        return {
          ...state,
          isdynamicFormTypeLoading: false,
         
        };      
        
      case actionTypes.EQUIPMENT_DTL_GET_REQUEST:
        return {
          ...state,
          iseqmtDtlTypeLoading: true,
        };
      case actionTypes.EQUIPMENT_DTL_GET_RECEIVE:

        
        return {
          ...state,
          //equipmentDynForms: DynamicFormData.map((dt, index) => ({ ...dt, id: index })),
          iseqmtDtlTypeLoading: false,
        };
  
      case actionTypes.EQUIPMENT_DTL_GET_FAIL:
        return {
          ...state,
          iseqmtDtlTypeLoading: false,
         
        };     
        case actionTypes.EQUIPMENT_MAINDATA_GET_REQUEST:
          return {
            ...state,
            isMainDataTypeLoading: true,
          };
        case actionTypes.EQUIPMENT_ROWDATA_GET_RECEIVE:
          console.log("My Main Data Action ", action.payload);
          
          return {
            ...state,
            equipmentRowData: action.payload.eqmntTypeColRowResponse.map((dt, index) => ({ ...dt, id: index })),
            equipmentDynForms: action.payload.EqmntTypeColResponse,
            isRowDataTypeLoading: false,
          };
    
        case actionTypes.EQUIPMENT_ROWDATA_GET_FAIL:
          return {
            ...state,
            isRowDataTypeLoading: false,
           
          };      
          case actionTypes.EQUIPMENT_ROWDATA_GET_REQUEST:
            return {
              ...state,
              isRowDataTypeLoading: true,
            };
          case actionTypes.EQUIPMENT_MAINDATA_GET_RECEIVE:
            console.log("My Main Data Action ", action.payload);
            let MainData = action.payload.machineData;
            let MainDataCols=action.payload.eqmntTypeColResponse;
            
            return {
              ...state,
              equipmentMainData: MainData.map((dt, index) => ({ ...dt, id: index })),
              equipmentMainDataCols:MainDataCols.map((dt, index) => ({ ...dt, id: index })),
              machineTypeDictUIColMappingsMS: action.payload.machineTypeDictUIColMappings,
              isMainDataTypeLoading: false,
            };
      
          case actionTypes.EQUIPMENT_MAINDATA_GET_FAIL:
            return {
              ...state,
              isMainDataTypeLoading: false,
             
            };      
  
    default:
      return state;
  }
};

export default equipmentTypeMainReducer;
