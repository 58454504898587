import { combineReducers } from "redux";
import commonReducer from "./Reducer/commonReducer";
import countryReducer from "../containers/Management/Countries/state/reducer";
import controlSystemReducer from "containers/Management/ControlSystem/state/reducer";
import currencyReducer from "containers/Management/Currencies/state/reducer";
import regionReducer from "containers/Management/Regions/state/reducer";
import oemReducer from "containers/Management/OEM/state/reducer";
import clusterReducer from "../containers/Management/Clusters/state/reducer";
import conditionReducer from "containers/Management/Conditions/state/reducer";
import speedUnitReducer from "containers/Management/SpeedUnits/state/reducer";
import productFormatReducer from "containers/Management/ProductFormat/state/reducer";
import ModelReducer from "containers/Management/Models/state/reducer";
import pfvReducer from "containers/Management/ProductFormatTypeValue/state/reducer";
import companyReducer from "containers/Management/Companies/state/reducer";
import areaReducer from "containers/Management/FunctionalStructure/Area/state/reducer";
import processReducer from "containers/Management/FunctionalStructure/Process/state/reducer";
import fnStrReducer from "containers/Management/FunctionalStructure/state/reducer";
import subProReducer from "containers/Management/FunctionalStructure/SubProcess/state/reducer";
import {equipmentActionReducer} from "containers/Management/EquipmentActions/state/reducer";
import functionReducer from "containers/Management/FunctionalStructure/Function/state/reducer";
import modelReducer from "containers/Management/FunctionalStructure/Model/state/reducer";
import { areaListReducer, ProcessReducer, SubProcessReducer, fpfReducer, functionReducer as newFunctionReducer, productFormatTypeReducer, functionProductFormatReducer } from "containers/Management/FunctionProductFormat/state/reducer"
import {companyForEquipmentReducer, companyListReducer, countryListReducer} from "containers/SearchViews/Companies/state/reducer";
import tokenReducer from "./Reducer/tokenReducer";
import searchEquipmentReducer from "containers/SearchViews/state/reducer";
import detailEquipmentReducer from "containers/SearchViews/state/detailReducer";
import modelFunctionReducer from "containers/SearchViews/FunctionalStructure/state/reducer";
import eqCompanyReducer, { EqReducer, FLocationReducer, SpeedUnitReducer, StatusReducer, parentEquipmentReducer } from "containers/SearchViews/EquipmentByCompany/state/reducer";
import {companyCoordinatorReducer, allCompanyCoordinatorReducer} from "containers/SearchViews/Coordinators/state/reducer";
import CompanyStatusReducer from "containers/Management/Reports/state/reducer";
import {formWorkFlowReducer, formStateFlowReducer, stepReducer, formTypeReducer } from "containers/DeveloperHomePage/FormWorkFlow/state/reducer";
import formStateReducer from "containers/SearchViews/StateList/reducer";
import formStepReducer from "containers/SearchViews/StepList/reducer";
import documentReducer from "containers/SearchViews/state/documentReducer";
import bulkUploadReducer from "containers/Management/UploadTemplate/state/reducer";
import eqDispSearchListReducer from "../containers/SearchViews/EquipmentDisposal/state/reducer";
import machineReserReducer from "../containers/SearchViews/MachineReservationSearch/state/reducer";
import adminRoleReducer from "containers/Management/AdministratorRole/state/reducer";
import updateTemplateReducer from "containers/Management/UpdateTemplate/state/reducer";
import fileUploadReducer from "containers/Management/BulkUploadStatus/state/reducer";
import {securityCompanyCoordinatorReducer} from "containers/Management/AdministratorCoordinators/state/reducer";
import downloadReducer from "containers/Management/DownloadTemplate/state/reducer";
import {functionalLocationReducer, companyFunctionalLocationsReducer} from "containers/Management/FunctionalLocations/state/reducer";
import validateCompanyReducer from "containers/Management/ValidationCompanyInformation/state/reducer";
import businessProcessReducer from "containers/BusinessProcess/DisposalRequest/state/reducer";
import flByIDReducer from "containers/BusinessProcess/DisposalRequest/stateNew/reducer";
import formInitiatorReducer from "containers/Management/FormInitiator/state/reducer"
import notificationsReducer from "layout/MainLayout/Header/HeaderContent/state/reducer";
import teamFocusReducer from "containers/Management/SamTeamFocus/state/reducer";
import mrfReducer from "containers/BusinessProcess/MachineReservationRequest/state/reducer";
import smartFilterReducer from "containers/Dashboard/CustomFilterState/reducer";
import statusReducer  from "containers/DeveloperHomePage/Status/state/reducer";
import devProductFormatTypeReducer  from "containers/DeveloperHomePage/ProductFormat/state/reducer";
import listTypeReducer from "containers/DesignSpeed/Lists/ListType/State/reducer"
import listTypeValueReducer from "containers/DesignSpeed/Lists/ListTypeValue/state/reducers"
import machineTypeReducer from "containers/DesignSpeed/ViewMachineType/state/reducer";
import mainScreenReducer from "containers/DesignSpeed/MainScreen/state/reducer";
import affiliateReducer from "containers/DesignSpeed/RequestHistory/state/reducers"                               
import allRequestHistoryReducer from "containers/DesignSpeed/Configuration/RequestReports/state/reducers"
import ruleGroupReducer from "containers/DesignSpeed/RuleGroup/State/reducer"
import equipmentTypeReducer from "containers/DesignSpeed/EquipmentColumnMapper/State/reducer"
import equipmentTypeMainReducer from "containers/DesignSpeed/EquipmentType/State/reducer"
import ruleGroupConfigReducer from "containers/DesignSpeed/RuleGroupConfiguration/state/reducer"
import dsadminreducer from "containers/DesignSpeed/Security/state/reducer"


const reducers = {
  common: commonReducer,
  country: countryReducer,
  region: regionReducer,
  oem: oemReducer,
  management: countryReducer,
  controlSystem: controlSystemReducer,
  cluster: clusterReducer,
  conditions: conditionReducer,
  currency: currencyReducer,
  speedUnits: speedUnitReducer,
  productFormats: productFormatReducer,
  model: ModelReducer,
  productFormatValue: pfvReducer,
  company: companyReducer,
  area: areaReducer,
  process: processReducer,
  fnStr: fnStrReducer,
  subProcess: subProReducer,
  function: functionReducer,
  model: modelReducer,
  areaList: areaListReducer,
  processList: ProcessReducer,
  subProcesslist: SubProcessReducer,
  functionProductFormatList: fpfReducer,
  functionList: newFunctionReducer,
  productFormatType: productFormatTypeReducer,
  functionProductFormatTypeList: functionProductFormatReducer,
  activeCountries: countryReducer,
  allCompaniesList: companyListReducer,
  token: tokenReducer,
  companyLists: companyReducer,
  searchEquipments: searchEquipmentReducer,
  allEquipments: searchEquipmentReducer,
  detailsEquipment: detailEquipmentReducer,
  modelFunctiolRel: modelFunctionReducer,
  eqCompany: eqCompanyReducer,
  FLlist: FLocationReducer,
  speedUnit: SpeedUnitReducer,
  status: StatusReducer,
  equipment: EqReducer,
  companyCoordinatorList: companyCoordinatorReducer,
  allCompanyCoordinatorList:allCompanyCoordinatorReducer,
  equipmentActionList: equipmentActionReducer,
  companyStatus: CompanyStatusReducer,
  formWorkFlowList: formWorkFlowReducer,
  formStepList: stepReducer,
  formStateList: formStateFlowReducer,
  formTypeList: formTypeReducer,
  formState: formStateReducer,
  formStep: formStepReducer,
  documents: documentReducer,
  bulkUpload : bulkUploadReducer,
  eqSearch: eqDispSearchListReducer,
  mchnSearch: machineReserReducer,
  roleList: adminRoleReducer,
  templateUpload : updateTemplateReducer,
  fileUpload : fileUploadReducer,
  securityCompanyCoordinator:securityCompanyCoordinatorReducer,
  download : downloadReducer,
  companyList: companyFunctionalLocationsReducer,
  functionalLocationList: functionalLocationReducer,
  validateCompanyValueList: validateCompanyReducer,
  businessProcess : businessProcessReducer,
  mrfReducer: mrfReducer,
  flByID: flByIDReducer,
  countryList: countryListReducer,
  // notification: notificationsReducer,
  makersPackersList: teamFocusReducer,
  initiatorData: formInitiatorReducer,
  notification: notificationsReducer, 
  parentEquipmentList :parentEquipmentReducer,
  smartFilter: smartFilterReducer,
  devstatus: statusReducer,
  devProductFormatType: devProductFormatTypeReducer,
  companyforEquipmentList: companyForEquipmentReducer,
  // Design speed Reducers
  ListType:listTypeReducer,
  ListTypeValue:listTypeValueReducer,
  machineTypeList: machineTypeReducer,
  mainScreen: mainScreenReducer,
  machineTypeMainData:equipmentTypeMainReducer,
  allRequestHistory: allRequestHistoryReducer,
  stgmainScreenList:affiliateReducer,
  stgmachineTypeDictUIColMappingsMS:affiliateReducer,
  activerulegroup:ruleGroupReducer,
  equipmentType: equipmentTypeReducer,
  equipmentPermTypeData:equipmentTypeMainReducer,
  equipmentDynForms:equipmentTypeMainReducer,
  ModelOemList:equipmentTypeMainReducer,
  activeColumnNameList: equipmentTypeReducer,
  equipmentColById:equipmentTypeReducer,
  equipmentMainData:equipmentTypeMainReducer,
  equipmentHistTypeData:equipmentTypeMainReducer,
  equipmentHistDictUIColMappingsMS:equipmentTypeMainReducer,
  ruleGroupConfig: ruleGroupConfigReducer,
  dseqmtcolconfig: ruleGroupConfigReducer,
  equipmentRowData:equipmentTypeMainReducer,
  dssysownerroleList:dsadminreducer,
  dsequipmentownerroleList:dsadminreducer,
  dssusersroleList:dsadminreducer,
  dsallequipmentownerroleList:dsadminreducer
}

const combinedReducers = Object.keys(reducers).length
  ? { ...reducers }
  : {
    default: (state) => (state == null ? (state = []) : state),
  };

export default combineReducers(combinedReducers);
